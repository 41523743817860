<template>
  <Header />
  <notifications position="top center" />
  <router-view v-slot="{ Component }">
    <transition
      name="custom-fade"
      enter-active-class="animate__animated animate__fadeIn animate__faster"
    >
      <component :is="Component" />
    </transition>
  </router-view>
  <Footer />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import "vueperslides/dist/vueperslides.css";
import "animate.css";
import 'aos/dist/aos.css'

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  mounted: function () {
    if (!this.$store.getters.worksLoaded) {
      this.$store.dispatch("getWorks");
      this.$store.dispatch("getClients");
      this.$store.dispatch("getTestimonials");
    } else if (this.$store.getters.checkExpiryDate) {
      this.$store.dispatch("updateCurrentTime");
      this.$store.dispatch("getWorks");
      this.$store.dispatch("getClients");
      this.$store.dispatch("getTestimonials");
    }
  },
};
</script>

<style lang="scss">
@import "./styles/MyFontsWebfontsKit.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");

html,
body {
  font-family: "TTRamillas-ExtraLight";
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
h1 {
  font-size: 80px;
  letter-spacing: -0.05em;
  line-height: 80px;
  font-weight: 300;
}
h2 {
  font-size: 61px;
  letter-spacing: -0.03em;
  line-height: 68px;
  font-weight: 300;
}
p {
  font-weight: 300;
  font-size: 21px;
  line-height: 30px;
}

button:hover {
  cursor: pointer;
}

.container-padding {
  margin: 20px;
}

@media screen and (max-height: $tabletHeight) {

}


@media screen and (max-width: $tabletWidth) {

}

@media screen and (max-width: $mobile) {
  .container-padding {
    margin: 0;
  }
  h1{
    font-size: 60px;
    line-height: 70px;
  }
  h2{
    font-size: 35px;
    line-height: 45px;
  }
}

.ca3-scroll-down-link {
  cursor: pointer;
  line-height: 60px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-left: - 64px;
  bottom: 20px;
  color: #fff;
  text-align: center;
  z-index: 5;
  animation: ca3_fade_move_down 2.5s ease-in-out infinite;
  img{
    margin: 0 auto;
    width: 128px;
  }
}

@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}

.vueperslides__arrow{
  opacity: 1;
  &:hover{
    opacity: 0.7;
  }
}

.vue-notification-group{
  top: 90px;
}

.vue-notification {
  font-family: $secondaryFont;
}
</style>
