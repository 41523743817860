import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import VueAxios from "vue-axios";
import easySpinner from "vue-easy-spinner";
import AOS from 'aos'
import Notifications from '@kyvg/vue3-notification'

library.add(faInstagram);

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(easySpinner, {
    prefix: "easy",
  })
  .use(AOS.init({
      once: true
    }
  ))
  .use(Notifications)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
