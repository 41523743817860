<template>
  <div class="top-nav">
    <router-link to="/">
      <img class="logo" src="/images/logo.png" alt="Austin Robbins Logo" />
    </router-link>
    <button @click="menuShow = true">
      <img class="menu-open" src="/images/menu-open.png" alt="Menu Open" />
    </button>
  <transition
    name="fade"
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
  >
    <div class="menu-container" v-show="menuShow">
      <div class="top">
        <img class="logo logo-in-menu" src="/images/logo.png" alt="Austin Robbins Logo" />
      </div>
      <div class="content">
        <router-link
          v-for="(item, i) in menuItems"
          v-bind:key="i"
          v-bind:to="item.link"
        >
          <span @click="closeMenu" role="link">{{ item.label }}</span>
        </router-link>
      </div>
      <div class="bottom">
        <div class="box">
          <a href="tel:424-253-0009" target="_blank" rel="noopener noreferrer"
            >424.253.0009</a
          >
        </div>
        <div class="box">
          <a
            href="mailto:agency@austinrobbins.com"
            target="_blank"
            rel="noopener noreferrer"
            >agency@austinrobbins.com</a
          >
        </div>
        <div class="box">
          <div class="link-div">
            <a
              href="https://www.instagram.com/austinrobbinsagency/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <font-awesome-icon :icon="['fab', 'instagram']" />
              <span> @austinrobbinsagency </span>
            </a>
          </div>
        </div>
      </div>
      <button @click="menuShow = false">
        <img
          class="menu-close"
          src="/images/menu-close.png"
          alt="Menu Close"
        />
      </button>
    </div>
  </transition>
</div>
</template>

<script>
export default {
  name: "Menu",
  methods: {
    closeMenu() {
      this.menuShow = false;
    },
  },
  data() {
    return {
      menuShow: false,
      menuItems: [
        {
          label: "Home",
          link: "/",
        },
        {
          label: "Works",
          link: "/works",
        },
        {
          label: "Team",
          link: "/team",
        },
        {
          label: "Reviews",
          link: "#reviews",
        },
        {
          label: "Inquire",
          link: "#inquire",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
button {
  background: none;
  border: none;
  position: absolute;
  right: 20px;
  &:hover {
    cursor: pointer;
  }
  img {
    height: 11px;
    width: 43px;
  }
  .menu-close {
    width: 39px;
    height: 39px;
  }
}
.top-nav {
  position: sticky;
  top: 0;
  background: $primaryColor;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
  z-index: 10;
  border-bottom: 1px solid #2E3646;
  .logo {
    display: block;
    width: auto;
    height: 17px;
  }
}

.menu-container {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  display: block;
  background-color: $primaryColor;
  text-align: center;
  z-index: 20;
  color: #fff;
  .top {
    .logo-in-menu{
      margin: 33px auto 0;
    }
  }
  button {
    position: absolute;
    top: 20px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
    a {
      color: #fff;
      text-transform: uppercase;
      text-decoration: none;
      font-size: 55px;
      margin: 20px 0;
      &:hover {
        color: $secondaryColor;
        text-decoration: line-through;
      }
    }
  }
  .bottom {
    position: relative;
    bottom: 0px;
    display: flex;
    padding: 0 60px;
    flex-wrap: wrap;
    .box {
      flex: 1;
      display: flex;
      justify-content: center;
    }
    .box:first-child > a {
      margin-right: 0 auto;
    }
    .box:last-child > a {
      margin: 0 auto;
    }
    .link-div{
      margin-left: auto;
    }
    a {
      color: #fff;
      text-decoration: none;
      font-size: 24px;
      line-height: 24px;
      display: flex;
      align-items: center;
      svg {
        margin-top: 3px;
        margin-right: 8px;
      }
      &:hover {
        color: $secondaryColor;
        text-decoration: line-through;
      }
    }
  }
}

@media screen and (max-width: $tabletWidth) {
  .menu-container{
    .content{
      height: 70%;
      min-height: 400px;
      a {
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        &:hover {
          color: $secondaryColor;
          text-decoration: line-through;
        }
      }
    }
    .bottom {
      position: relative;
      bottom: 0px;
      display: flex;
      padding: 0;
      flex-wrap: wrap;
      flex-direction: column;
      margin-top: 20px;
      .box {
        flex: 1;
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
      .box:first-child > a {
        margin-right: unset;
      }
      .box:last-child > a {
        margin-left: unset;
      }
      .link-div{
        margin-left: unset;
      }
      a {
        display: flex;
        align-items: center;
        svg {
          margin-top: 3px;
          margin-right: 8px;
        }
      }
    } 
  }
}
@media screen and (max-width: $mobile) {
  button{
    .menu-close {
      width: 30px;
      height: 30px;
    }
  }
  .top-nav {
    justify-content: flex-start;
    .logo {
      height: 12px;
      margin-left: 20px;
    }
    button {
      position: absolute;
      top: 35px;
      right: 10px;
      .menu-open{
        width: 40px;
      }
    }
  }
  .menu-container{
    .content{
      height: 60%;
      min-height: 400px;
      a {
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 35px;
        margin: 15px 0;
        &:hover {
          color: $secondaryColor;
          text-decoration: line-through;
        }
      }
    }
    button {
      position: absolute;
      top: 30px;
      right: 15px;
    }
    .top{
      display: none;
    }
    .bottom {
      position: relative;
      bottom: 0px;
      display: flex;
      padding: 0;
      flex-wrap: wrap;
      flex-direction: column;
      margin-top: 20px;
      .box {
        flex: 1;
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
      .box:first-child > a {
        margin-right: unset;
      }
      .box:last-child > a {
        margin-left: unset;
      }
      .link-div{
        margin-left: unset;
      }
      a {
        color: #fff;
        text-decoration: none;
        font-size: 24px;
        line-height: 24px;
        display: flex;
        align-items: center;
        svg {
          margin-top: 3px;
          margin-right: 8px;
        }
      }
    } 
  }
}
</style>
