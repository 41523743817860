<template>
  <section class="homevideo">
    <div class="homevideo__container">
      <div class="homevideo__background">
        <div class="homevideo__background__video">
          <figure class="homevideo__background__asset">
            <video
              autoplay
              class="video"
              data-load="objectFit"
              loop
              muted
              playsinline
            >
              <source
                data-src="https://api.uptowncreative.io/uploads/_/assets/chadrogers/austin-robbins.mp4"
                type="video/mp4"
                src="https://api.uptowncreative.io/uploads/_/assets/chadrogers/austin-robbins.mp4"
              />
            </video>
          </figure>
        </div>
        <div class="homevideo__background__mobile">
          <figure class="homevideo__background__asset">
            <img alt="Austin Robbins" src="/images/video-intro.jpg" />
          </figure>
        </div>
      </div>
      <div class="homevideo__gradient"></div>
      <div class="homevideo__foreground">
        <div class="homevideo__content">
          <h1>
            Elevate Your<br />
            Real Estate Prestige
          </h1>
          <p>
            Crafting Exceptional Experiences<br />
            for Discerning Audiences
          </p>
          <router-link class="btn" to="#inquire"> Get Started </router-link>
          <router-link
            to="#team"
            class="ca3-scroll-down-link"
          >
            <img src="/images/arrow-down.png" alt="" class="ca3-scroll-down-arrow">
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.homevideo {
  &__container {
    height: calc(100vh - 84px);
    width: 100%;
    min-height: 480px;
  }
  &__background {
    &__video {
      display: block;
      &.isloading {
        background: url("/images/initial-slide.jpg") cover;
      }
    }
    &__asset {
      margin-top: 84px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      .video {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__mobile {
      display: none;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  &__gradient {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to bottom, #000, #000);
    opacity: 0.35;
  }
  &__foreground {
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #fff;
  }
  .homevideo__content {
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    h1 {
      margin: 0 auto;
      margin-top: 10%;
      text-transform: uppercase;
    }
    p {
      margin: 0 auto;
      margin-top: 20px;
      max-width: 600px;
      letter-spacing: -0.04em;
      font-size: 27px;
      line-height: 34px;
    }
    .btn {
      font-family: $secondaryFont;
      width: 200px;
      height: 64px;
      border: none;
      border-radius: 40px;
      border: 1px solid #fff;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 50px auto 0;
      font-size: 16px;
      letter-spacing: 0.05em;
    }
  }
}
figure {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: $mobile) {
  .homevideo {
    .homevideo__content {
      h1 {
        font-size: 36px;
        line-height: 50px;
        margin: 0 20px;
      }
      p{
        font-size: 20px;
        line-height: 24px;
        margin: 0 20px;
        margin-top: 40px;
      }
    }
  }
}
</style>

<script>
export default {
  name: "VideoBackground",
  data() {
    return {
      isloading: true,
    };
  },
};
</script>
