<template>
  <div class="brands">
    <img
      v-for="(item, i) in images"
      v-bind:src="item.url"
      v-bind:alt="item.alt"
      v-bind:key="i"
      data-aos="fade-up" data-aos-duration="1000" 
      v-bind:data-aos-delay="`${(i+1) * 400}`"
    />
  </div>
</template>

<script>
export default {
  name: "Branding",
  data() {
    return {
      images: [
        {
          url: "/images/branding/brand-a.png",
          alt: "Auberge Resorts Brand Logo",
        },
        {
          url: "/images/branding/brand-b.png",
          alt: "La Quinta Brand Logo",
        },
        {
          url: "/images/branding/brand-c.png",
          alt: "RBC Brand Logo",
        },
        {
          url: "/images/branding/brand-d.png",
          alt: "Meredith Schlosser Brand Logo",
        },
        {
          url: "/images/branding/brand-e.png",
          alt: "AAA Brand Logo",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.brands {
  margin: 30px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 900px;
  img {
    display: block;
    width: 20%;
    padding: 20px 30px;
  }
}

@media screen and (max-width: $mobile) {
  .brands {
    flex-wrap: wrap;
    img {
      display: block;
      width: 50%;
      padding: 20px 30px;
    }
  }
}
</style>
