<template>
  <div class="footer">
    <reviews />
    <contact-form />
    <branding />
    <footer-credits />
  </div>
</template>

<script>
import Reviews from "./Reviews.vue";
import ContactForm from "./ContactForm.vue";
import Branding from "./Branding.vue";
import FooterCredits from "./FooterCredits.vue";

export default {
  name: "Footer",
  components: {
    Reviews,
    ContactForm,
    Branding,
    FooterCredits,
  },
};
</script>
<style scoped lang="scss"></style>
