<template>
  <div class="home">
    <video-background />
    <home-page-banner />
    <div class="selected-works" id="work">
      <div class="title-section">
        <h1 data-aos="fade-up" >Selected Works</h1>
        <p data-aos="fade-up" >2009 - {{ currentYear }}</p>
      </div>
      <hr />
      <div class="works">
        <div
          v-for="(item, i) in works"
          v-bind:key="i"
          class="work"
          v-bind:id="item.Id"
        >
          <div class="media" v-bind:data-aos="i % 2 === 0 ? 'fade-up' : 'fade-up'" data-aos-duration="1000" >
            <div
              v-if="item.HomepageDisplayAssetType === 'video'"
              title="video"
              class="embed-container"
            >
              <iframe
                v-bind:title="item.Client"
                :src="
                  'https://player.vimeo.com/video/' +
                  item.HomepageDisplayAssetUrl
                "
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
            <img
              v-if="item.HomepageDisplayAssetType === 'image'"
              v-bind:src="url + item.HomepageDisplayAssetUrl"
              v-bind:alt="item.Client"
            />
          </div>
          <div class="description">
            <h2 data-aos="fade-up" data-aos-duration="1000" >
              {{ item.Client }}
            </h2>
            <p data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" >
              {{ item.ShortDescription }}
            </p>
            <router-link v-bind:to="`/works/#${item.Id}`">
              <img
                class="arrow-next"
                data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800" 
                src="/images/arrow-right.png"
                alt="Go to work page"
              />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoBackground from "../components/VideoBackground.vue";
import HomePageBanner from "../components/HomePageBanner.vue";
import AOS from 'aos'
export default {
  name: "Home",
  components: {
    VideoBackground,
    HomePageBanner,
  },
  data() {
    return {
      url: "https://strapi.uptowncreative.io",
      videoUrl: "https://player.vimeo.com/video/",
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    works() {
      return this.$store.getters.works.filter((work) => {
        return work.FeaturedOnHomepage;
      }).sort((a, b) => {
        return a.HomepageOrder - b.HomepageOrder;
      });
    },
  },
  mounted: function(){
    AOS.refresh();
  }
};
</script>

<style scoped lang="scss">
.title-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 80px 5% 0px;
  h1 {
    text-transform: uppercase;
    margin: 0;
    letter-spacing: -0.03em;
  }
  p {
    font-family: $secondaryFont;
    margin: 0;
  }
}
hr {
  border-bottom: none;
  margin: 5px 5% 80px;
}
.work {
  display: flex;
  align-items: center;
  margin: 60px 0;
  .media {
    width: 65%;
    img {
      width: 100%;
    }
  }
  .description {
    margin-left: 60px;
    h2 {
      margin-top: 0;
      max-width: 600px;
      line-height: 68px;
      margin-bottom: 40px;
    }
    p {
      font-size: 27px;
      line-height: 34px;
      max-width: 300px;
      letter-spacing: -0.04em;
      margin-bottom: 40px;
    }
    .arrow-next {
      width: 34px;
      height: auto;
    }
  }
  &:nth-child(odd) {
    margin-right: 5%;
  }
  &:nth-child(even) {
    flex-direction: row-reverse;
    .media {
      margin-left: 5%;
      align-self: flex-end;
    }
  }
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: $mobile) {
  .title-section {
    h1 {
      font-size: 25px;
      line-height: 35px;
    }
    p {
      font-size: 16px;
    }
  }
  .work {
    flex-wrap: wrap;
    .media {
      width: 100%;
    }
    .description {
      text-align: center;
      margin: 0 auto;
      h2{
        margin: 30px 20px;
      }
      p {
        font-size: 16px;
        line-height: 20px;
        margin: 0 20px;
      }
      .arrow-next {
        margin-top: 40px;
      }
    }
    &:nth-child(even) {
      flex-direction: row;
      .media {
        margin-left: 0;
        align-self: center;
      }
    }
    &:nth-child(odd) {
      flex-direction: row;
      margin-right: 0;
    }
  }
}
</style>
