<template>
  <div class="contact-form-container" id="inquire">
    <div class="title" data-aos="fade-up" data-aos-duration="800" >
      <h1>DISCOVER PRESTIGE</h1>
    </div>
    <div class="description" data-aos="fade-up" data-aos-duration="800" >
      <p>
        Connect with Us for Unparalleled Real Estate Marketing Excellence. Complete the form below or directly reach us at
        <a href="tel:424-253-0009" target="_blank" rel="noopener noreferrer"
          >424.253.0009</a
        >
        or
        <a
          href="mailto:agency@austinrobbins.com"
          target="_blank"
          rel="noopener noreferrer"
          >agency@austinrobbins.com</a
        > for immediate assistance.
      </p>
    </div>
    <form 
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      @submit.prevent="handleSubmit">
      <input type="hidden" name="form-name" value="contact" />
      <div class="form-input-container" data-aos="fade-up" data-aos-duration="800" >
        <div class="left">
          <div class="form-group">
            <label for="name">Name</label>
            <input type="text" name="name" v-model="form.name" />
          </div>
          <div class="form-group">
            <label for="name">Email *</label>
            <input
              required
              type="email"
              name="email"
              v-model="form.email" 
            />
          </div>
          <div class="form-group">
            <label for="name">Phone</label>
            <input
              type="text"
              name="phone"
              v-model="form.phone"
            />
          </div>
        </div>
        <div class="right">
          <div class="form-group">
            <label for="message">Message</label>
            <textarea name="message" id="message" cols="30" rows="10" v-model="form.message"/>
          </div>
        </div>
      </div>
      <button type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
export default {
  name: "ContactForm",
  data(){
    return{
      form:{
        name: '',
        email: '',
        phone: '',
        message: ''
      },
    }
  },
  methods: {
    encode (data) {
      return Object.keys(data)
        .map(
        key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    handleSubmit () {
      const axiosConfig = {
          header: { "Content-Type": "application/x-www-form-urlencoded" }
      };
      const self = this;
      this.axios.post("/",
          this.encode({
              "form-name": "contact",
              ...this.form
          }),
          axiosConfig
      )
      .then(() => {
        self.$notify({
          title: "Success",
          text: "We have received your message, we will contact you shortly.",
          type: "success",
          timer: 3000
        });
        self.form.name = '';
        self.form.email = '';
        self.form.phone = '';
        self.form.message = '';
      })
      .catch(() => {
        self.$notify({
          title: "Error",
          text: "Error submitting form data, please try again.",
          type: "error",
          timer: 300000
        });
      })
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.contact-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .title{
    text-transform: uppercase;
  }
  .description > p {
    widows: 80%;
    max-width: 875px;
    margin: 0 auto;
    a {
      font-weight: bold;
      color: #000;
      text-decoration: none;
    }
  }
  .form-input-container {
    display: flex;
    text-align: left;
    margin-top: 80px;
    .left {
      width: 50%;
      margin-right: 40px;
    }
    .right {
      flex: 1;
    }
    .form-group {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      label {
        font-size: 26px;
        text-transform: uppercase;
        margin-bottom: 0;
      }
      input {
        font-family: "TTRamillas-ExtraLight";
        font-size: 21px;
        font-weight: 300;
        width: 330px;
        border: none;
        border-bottom: 1px solid #000;
        max-width: 90%;
        -webkit-appearance: none;
        border-radius: 0;
      }
      textarea {
        font-family: "TTRamillas-ExtraLight";
        width: 400px;
        height: 230px;
        padding: 15px 15px;
        font-size: 21px;
        resize: none;
        max-width: 85%;
      }
    }
  }
  button {
    background: $primaryColor;
    width: 240px;
    height: 64px;
    border: none;
    border-radius: 40px;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    margin: 40px 0;
    font-size: 20px;
    line-height: 35px;
    font-family: $secondaryFont;
    font-weight: 400;
  }
}

@media screen and (max-width: $tabletWidth) {
  .contact-form-container {
    .form-input-container {
      .title{
        h1{
          margin: 0 20px;
          margin-bottom: 20px;
          margin-top: 60px;
        }
      }
      .description{
        margin: 10px 20px;
      }
      display: flex;
      text-align: left;
      margin-top: 80px;
      .left {
        width: 50%;
        margin-right: 40px;
      }
      .right {
        flex: 1;
      }
      .form-group {
        input {
          width: 300px;
        }
        textarea {
          width: 300px;
          max-width: 90%;
        }
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  form{
    width: 100%;
  }
  .contact-form-container {
    width: 100%;
    margin: 40px auto;
    .title{
      h1{
        margin: 0 20px;
        margin-bottom: 20px;
        margin-top: 60px;
      }
    }
    .description{
      margin: 10px 20px;
    }
    .form-input-container {
      width: 100%;
      flex-wrap: wrap;
      .left {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
        padding-left: 20px;
      }
      .right{
        max-width: 100%;
        margin-left: 0;
        padding-left: 20px;
      }
      .form-group {
        margin-left: 0;
        textarea {
          max-width: 90%;
        }
      }

    }
  }
}
</style>