<template>
  <div class="reviews-container container-padding" id="reviews" ref="target">
    <div class="spinner" v-if="!$store.getters.testmonialsLoaded">
      <easy-spinner size="80" color="#F3D4AE" />
    </div>
    <div class="slideshow-container" v-else>
      <vueper-slides
        ref="myVueperSlides"
        class="no-shadow"
        @slide="setCurrenSlide($event)"
        v-bind:pauseOnTouch="false"
        v-bind:pauseOnHover="false"
        v-bind:touchable="windowWidth > 680 ? false : true"
        v-bind:bullets="windowWidth > 680 ? false : true"
        v-bind:arrows="windowWidth > 680 ? true : false"
        v-bind:fixed-height="textHeight"
      >
        <vueper-slide v-for="(item, i) in testimonials" v-bind:key="i">
          <template #content>
            <div class="review-content" data-aos="fade-up" data-aos-duration="800" >
              <h2 class="review-slideshow-description">
                "{{ item.Testimonial }}"
              </h2>
              <p class="name" v-if="item.Name">
                {{ item.Name }}
              </p>
              <p class="team" v-if="item.Team">
                {{ item.Team }}
              </p>
              <p class="volumn" v-if="item.TransactionVolume">
                {{ item.TransactionVolume }}
              </p>
            </div>
          </template>
        </vueper-slide>
      </vueper-slides>
    </div>
    <hr />
    <div class="images">
      <img
        v-for="(item, i) in images"
        v-bind:src="item.url"
        v-bind:alt="item.alt"
        v-bind:key="i"
        data-aos="fade-up" data-aos-duration="1000"
        
        v-bind:data-aos-delay="`${(i+1) * 600}`"
      />
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import { ref } from 'vue'
import { useElementVisibility } from '@vueuse/core'

export default {
  name: "Reviews",
  components: {
    VueperSlides,
    VueperSlide,
  },
  setup() {
    const target = ref(null)
    const targetIsVisible = useElementVisibility(target)

    return {
      target,
      targetIsVisible,
    }
  },
  data() {
    return {
      images: [
        {
          url: "/images/reviews/clients.png",
          alt: "100+ clients served",
        },
        {
          url: "/images/reviews/years.png",
          alt: "10+ years",
        },
        {
          url: "/images/reviews/vendor.png",
          alt: "Preffered Vendor Compass",
        },
      ],
      currentSlideIndex: 0,
      testimonialsUpdated: false,
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };
  },
  methods: {
    setCurrenSlide(event) {
      this.currentSlideIndex = event.currentSlide.index;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
  },
  computed: {
    testimonials() {
      return this.$store.getters.testimonials;
    },
    textHeight() {
      if (this.testimonials.length > 0) {
        const actualWidth = this.windowWidth * 0.7;
        let wordsPixel = 30;
        let heightBase = 70;
        let baseHeight = 350;
        if(this.windowWidth < 680){
          heightBase = 40;
          baseHeight = 200;
          wordsPixel = 18;
        }
        const wordsPerWidth = actualWidth / wordsPixel
        //const wordsPerLinePerWidth = actualWidth / wordsPerWidth;
        const totalHeight =
          (this.testimonials[this.currentSlideIndex].Testimonial.length /
            wordsPerWidth) *
          heightBase;
        return String(baseHeight + totalHeight) + "px";
      }
      return "500px";
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped lang="scss">
.reviews-container {
  background: $primaryColor;
  .spinner {
    padding: 80px 0;
    svg {
      display: block;
      margin: 0 auto;
    }
  }
  .images {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
    img {
      width: 230px;
      height: auto;
      margin: 30px 5%;
    }
  }
  ::v-deep .review-content {
    display: flex;
    flex-direction: column;
    margin: 100px 15%;
    h2 {
      color: $secondaryColor;
    }
    p {
      font-family: $secondaryFont;
      font-size: 18px;
      line-height: 24px;
      color: #fff;
      margin: 0;
    }
    .name {
      text-transform: uppercase;
    }
    .team,
    .volume {
      font-weight: bold;
    }
  }
  ::v-deep .slideshow-container{
    .vueperslides__arrows > .vueperslides__arrow--prev{
      display: none;
    }
    .vueperslides__bullets{
      .default {
        width:10px;
        height:10px;
        border-radius: 10px;
        color: #fff;
        background-color: #fff;
        box-shadow: none;
      }
    }
    .vueperslides__bullet--active{
      .default {
        width:10px;
        height:10px;
        border-radius: 10px;
        color: $secondaryColor;
        background-color: $secondaryColor;
        border-width: unset;
        box-shadow: none;
      }
    }
  }
  hr{
    width: 80%;
    height: 1px;
    border: none;
    border-top: 1px solid #fff;
    margin: 40px auto 0;
  }
}
@media screen and (max-width: $tabletWidth) {
  .reviews-container {
    .images {
      img {
        width: 150px;
        height: auto;
        margin: 30px 5%;
      }
    }
  }
}
@media screen and (max-width: $mobile) {
  .reviews-container {
    ::v-deep .review-content {
      margin: 40px 20px;
      h1{
        font-size: 40px;
      }
    }
    hr{
      margin: 20px auto;
    }
    .images {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px 0;
      padding-bottom: 80px;
      img {
        width: 230px;
        height: auto;
        margin: 30px 5%;
      }
    }
  }
}
</style>
