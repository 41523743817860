import { createStore } from "vuex";
import axios from "axios";

const TWO_HOURS = 2 * 60 * 60 * 1000;

export default createStore({
  state: {
    testimonials: [],
    clients: [],
    works: [],
    clientsLoaded: false,
    testmonialsLoaded: false,
    worksLoaded: false,
    expiryDate: new Date(),
  },
  getters: {
    testimonials: (state) => {
      return state.testimonials;
    },
    clients: (state) => {
      return state.clients;
    },
    works: (state) => {
      return state.works;
    },
    checkExpiryDate: (state) => {
      return new Date() - state.expiryDate > TWO_HOURS;
    },
    clientsLoaded: (state) => {
      return state.clientsLoaded;
    },
    testmonialsLoaded: (state) => {
      return state.testmonialsLoaded;
    },
    worksLoaded: (state) => {
      return state.worksLoaded;
    },
  },
  mutations: {
    getTestimonials(state) {
      axios
        .get(
          "https://strapi.uptowncreative.io/austin-robbins-testimonials?_sort=Order"
        )
        .then((response) => {
          state.testimonials = response.data;
        });
      state.testmonialsLoaded = true;
    },
    getClients(state) {
      axios
        .get(
          "https://strapi.uptowncreative.io/austin-robbins-clients?_sort=Order"
        )
        .then((response) => {
          state.clients = response.data;
        });
      state.clientsLoaded = true;
    },
    getWorks(state) {
      axios
        .get(
          "https://strapi.uptowncreative.io/austin-robbins-works?_sort=Order"
        )
        .then((response) => {
          state.works = response.data;
        });
      state.worksLoaded = true;
    },
    updateCurrentTime(state) {
      state.expiryDate = new Date();
    },
    clientsLoaded(state) {
      state.clientsLoaded = true;
    },
    testmonialsLoaded(state) {
      state.testmonialsLoaded = true;
    },
    worksLoaded(state) {
      state.worksLoaded = true;
    },
  },
  actions: {
    getTestimonials(context) {
      context.commit("getTestimonials");
    },
    getClients(context) {
      context.commit("getClients");
    },
    getWorks(context) {
      context.commit("getWorks");
    },
    updateCurrentTime(context) {
      context.commit("updateCurrentTime");
    },
    clientsLoaded(context) {
      context.commit("clientsLoaded");
    },
    testmonialsLoaded(context) {
      context.commit("testmonialsLoaded");
    },
    worksLoaded(context) {
      context.commit("worksLoaded");
    },
  },
  modules: {},
});
