<template>
  <div class="footer-credits">
    <img src="/images/logo-vertical.png" alt="Austin Robbins Logo" />
    <p>
      Copyright © {{ currentYear }} Uptown Creative, Inc. operating as (o/a) Austin Robbins Agency. <router-link to="/privacy-policy"> Privacy Policy.</router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: "FooterCredits",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped lang="scss">
.footer-credits {
  background: $primaryColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  color: #fff;
  img {
    margin-top: 100px;
    margin-bottom: 50px;
    width: 170px;
    height: auto;
  }
  p {
    font-family: $secondaryFont;
    line-height: 35px;
    font-size: 15px;
    margin: 0 30px;
    margin-bottom: 30px;
    text-align: center;
  }
  a{
    text-decoration: none;
    color: #fff;
  }
}
@media screen and (max-width: $mobile) {
  .footer-credits {
    p{
      line-height: 20px;
      font-size: 14px;
    }
  }
}
</style>
