<template>
  <div class="team container-padding" id="team">
    <div class="spinner" v-if="!$store.getters.clientsLoaded">
      <easy-spinner size="80" color="#F3D4AE" />
    </div>
    <div class="container" v-else>
      <div class="slideshow-container" ref="target">
        <vueper-slides
          ref="clientSlide"
          class="no-shadow slideshow"
          fade
          autoplay
          v-bind:duration="5000"
          v-bind:pauseOnTouch="false"
          v-bind:pauseOnHover="false"
          v-bind:touchable="false"
          v-bind:bullets="false"
          v-bind:arrows="false"
          v-bind:fixed-height="true"
        >
          <vueper-slide v-for="(item, i) in clients" v-bind:key="i">
            <template #content>
              <div class="content">
                <div class="content-inside">
                  <img
                    v-bind:src="url + item.Image.url"
                    v-bind:alt="item.Client"
                    data-aos="fade-right"
                    data-aos-duration="800"
                  />
                  <router-link
                    v-bind:to="`/works/#${item.Id}`"
                    class="client"
                    v-if="item.Client"
                    data-aos="fade-right"
                    data-aos-delay="400"
                    data-aos-duration="800"
                  >
                    {{ item.Client }}
                  </router-link>
                  <router-link
                    v-bind:to="`/works/#${item.Id}`"
                    class="sale"
                    v-if="item.Sale"
                    data-aos="fade-right"
                    data-aos-delay="800"
                    data-aos-duration="800"
                  >
                    {{ item.Sale }}
                  </router-link>
                </div>
              </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
      <div class="static-text">
        <h2 data-aos="fade-up" data-aos-duration="800" >
          The Choice of<br />
          Industry Leaders<br />
          and Esteemed<br />
          Brands Nationwide<br />
        </h2>
        <p data-aos="fade-up" data-aos-delay="400" data-aos-duration="800" >
          Amplifying Our Clients'<br />
          Expertise and Distinction in<br />
          the Luxury Real Estate Market.
        </p>
      </div>
    </div>
    <router-link
      to="#work"
      class="ca3-scroll-down-link"
    >
      <img src="/images/arrow-down.png" alt="" class="ca3-scroll-down-arrow">
    </router-link>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import { ref } from 'vue'
import { useElementVisibility } from '@vueuse/core'

export default {
  name: "HomePageBanner",
  components: {
    VueperSlides,
    VueperSlide,
  },
  setup() {
    const target = ref(null)
    const targetIsVisible = useElementVisibility(target)

    return {
      target,
      targetIsVisible,
    }
  },
  data() {
    return {
      currentSlideIndex: 0,
      clientsUpdated: false,
      url: "https://strapi.uptowncreative.io",
    };
  },
  methods: {
    autoplay() {
      if (this.clients.length > 0 && !this.clientsUpdated) {
        setTimeout(() => {
          this.$refs.clientSlide.next();
          this.clientsUpdated = true;
        }, 5000);
      }
    },
  },
  computed: {
    clients() {
      return this.$store.getters.clients;
    },
  },
  watch: {
    targetIsVisible: function () {
      if(this.targetIsVisible && this.clients.length > 0){
        this.autoplay();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.team {
  height: 100vh;
  background: $primaryColor;
  color: #fff;
  display: flex;
  align-items: center;
  position: relative;
  .container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    ::v-deep .slideshow-container{
      width: 50%;
      .vueperslides--fixed-height {
        height: 80vh;
        max-height: 650px;
      }
      .content {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .content-inside{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
        img {
          min-height: 300px;
          height: 60vh;
          max-height: 600px;
          width: auto;
        }
        a {
          font-family: $secondaryFont;
          font-size: 21px;
          text-decoration: none;
          line-height: 30px;
        }
        .client {
          margin-top: 30px;
          color: #fff;
          font-weight: 300;
          text-transform: uppercase;
        }
        .sale {
          margin-top: 5px;
          color: $secondaryColor;
          font-weight: 400;
        }
      }
    }
    .static-text {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 40px;
      h2 {
        color: $secondaryColor;
        margin-bottom: 10px;
      }
      p{
        font-family: $secondaryFont;
        font-weight: 300;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .team {
    height: unset;
    .container {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      ::v-deep .slideshow-container{
        width: 100%;
        .vueperslides--fixed-height {
          height: 750px;
          max-height: 800px;
        }
        .content {
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          .content-inside{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          img {
            min-height: 300px;
            height: 60vh;
            max-height: 600px;
            width: auto;
          }
          a {
            margin: 0 20px;
            text-align: center;
          }
        }
      }
      .static-text {
        margin-left: 0;
        padding: 0;
        width: 100%;
        text-align: center;
        h2 {
          margin: 20px auto;
          margin-top: 40px
        }
        p{
          margin: 20px auto;
          margin-bottom: 40px;
        }
      }
    }
  }
}
</style>
