import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'Austin Robbins Agency',
      metaTags: [
        {
          name: 'description',
          content: 'Austin Robbins Home page.'
        },
      ]
    }
  },
  {
    path: "/team",
    name: "Team",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Team.vue"),
      meta: {
        title: 'Austin Robbins Agency - Team',
        metaTags: [
          {
            name: 'description',
            content: 'Austin Robbins Team page.'
          },
        ]
      }
  },
  {
    path: "/works",
    name: "Work",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Work.vue"),
      meta: {
        title: 'Austin Robbins Agency - Works',
        metaTags: [
          {
            name: 'description',
            content: 'Austin Robbins works page.'
          },
        ]
      }
  },
  {
    path: "/terms",
    name: "Terms",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Term.vue"),
      meta: {
        title: 'Austin Robbins Agency - Term',
        metaTags: [
          {
            name: 'description',
            content: 'Terms'
          },
        ]
      }
  },
  {
    path: "/privacy-policy",
    name: "Privacy",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Privacy.vue"),
      meta: {
        title: 'Austin Robbins Agency - Privacy Policy',
        metaTags: [
          {
            name: 'description',
            content: 'Privacy Policy'
          },
        ]
      }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      if(to.path === '/works/'){
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve(
              { 
                el: to.hash,
                behavior: "smooth",
                top: 84, 
              })
          }, 1000)
        })
      }
      if(to.hash === '#reviews'){
        return {
          el: to.hash,
          behavior: "smooth",
          top: 104,
        };
      }
      else {
        return {
          el: to.hash,
          behavior: "smooth",
          top: 84,
        };
      }
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
